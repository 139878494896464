<script>
import NProgress from "nprogress";

export default {
    name: "form-forgot-username-support",
    data() {
        return {
            userEmail: "",
            firstName: "",
            lastName: "",
            contactNumber: "",
            companyName: ""
        };
    },
    methods: {
        login: function () {
            const username = this.username;
            const password = this.password;
            NProgress.start();
            this.$store
                .dispatch("auth/login", {username, password})
                .then(() => {
                    this.$store.dispatch("auth/successLogin");
                    NProgress.done();
                    this.$router.push("/home");
                })
                .catch(() => {
                    NProgress.done();
                });
        }
    }
};
</script>

<template>
    <form method="POST" @submit.prevent="login">
        <h2 class="header-text">Forgot your Password?</h2>
        <p>Get Help by providing us with more information about your account and one of our team members
            will contact you.</p>
        <br/>
        <p>Enter the Email Address That Was Registered With Your Account</p>
        <div class="form-group">
            <input
                    type="email"
                    class="input-field error"
                    placeholder="Enter your email address"
                    name="userEmail"
                    v-model="userEmail"
            />
            <div class="w-100 text-right">
                <span class="error-text">
                  <i class="bx bxs-error" style="color:#ff5e5e"></i>&nbsp;We could not find an account with that email address.
                </span>
            </div>
        </div>
        <div class="form-group">
            <label for="email">First Name</label>
            <input
                    id="email"
                    type="email"
                    class="input-field error"
                    placeholder="Your First Name"
                    name="firstname"
                    v-model="firstName"
            />
            <div class="w-100 text-right">
                <span class="error-text">
                  <i class="bx bxs-error" style="color:#ff5e5e"></i>&nbsp;Field is required.
                </span>
            </div>
        </div>
        <div class="form-group">
            <label for="lastname">Last Name</label>
            <input
                    id="lastname"
                    type="text"
                    class="input-field error"
                    placeholder="Your Last Name"
                    name="lastName"
                    v-model="lastName"
            />
            <div class="w-100 text-right" v-show="false">
                <span class="error-text">
                  <i class="bx bxs-error" style="color:#ff5e5e"></i>&nbsp;Field is required.
                </span>
            </div>
        </div>
        <div class="form-group">
            <label for="contact-number">Contact Number</label>
            <input
                    id="contact-number"
                    type="text"
                    class="input-field error"
                    placeholder="Your Contact Number"
                    name="contactNumber"
                    v-model="contactNumber"
            />
            <div class="w-100 text-right" v-show="false">
                <span class="error-text">
                  <i class="bx bxs-error" style="color:#ff5e5e"></i>&nbsp;Field is required.
                </span>
            </div>
        </div>
        <div class="form-group">
            <label for="company-number">Company Name The Account is Registered With</label>
            <input
                    id="company-number"
                    type="text"
                    class="input-field error"
                    placeholder="Company Name"
                    name="companyName"
                    v-model="companyName"
            />
            <div class="w-100 text-right" v-show="false">
                <span class="error-text">
                  <i class="bx bxs-error" style="color:#ff5e5e"></i>&nbsp;Field is required.
                </span>
            </div>
        </div>
        <div class="form-group">
            <button type="submit" class="btn input-btn">Submit to Support</button>
        </div>
    </form>
</template>